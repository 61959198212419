import React from "react";
import "./QuestionairComplete.scss";
import thumbsupImg from "./../../assets/images/welldone.gif";

function QuestionairComplete() {
  return (
    <div className="container-thumbsup">
      <h2>Well Done!</h2>
      <img src={thumbsupImg} alt="thumbsup logo" width={200} height={200} />
      <p>Your assessment has been successfully submitted!</p>
      <p>Thanks for taking out time with us.</p>
    </div>
  );
}

export default QuestionairComplete;
