import { Button } from "@mui/material";

type QuestionOptionProps = {
  option: string;
  selected: boolean;
  selectOption: (option: string) => void;
};

function QuestionOption(props: QuestionOptionProps) {
  const { option, selected, selectOption } = props;
  const handleClick = () => {
    selectOption(option);
  };

  return (
    <Button size="large" onClick={handleClick} color="primary" variant={selected ? "contained" : "outlined"}>
      {option}
    </Button>
  );
}

export default QuestionOption;
