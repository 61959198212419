import { useState } from "react";

import "./Home.scss";
import homeBgImg from "./../../assets/images/home_image.jpg";
import { fireStoreConfig, fireStoreService } from "../../firebase";
import { appConfig } from "../../Config";
import { serverTimestamp } from "firebase/firestore";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

type HomeProps = {
  startTest: React.Dispatch<React.SetStateAction<boolean>>;
};

const Home = (props: HomeProps) => {
  const { startTest } = props;
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const terms: string[] = appConfig.terms;

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    // Step 1: extract form data
    const formData = new FormData(e.target);
    const email = formData.get("email") as string;

    // Step 2: get userInfo from firestore
    const dbUser = await fireStoreService.getDocByEmail(fireStoreConfig.usersCollection, email);
    fireStoreService.userRef = dbUser?.ref;

    // Step 3: validate user
    if (dbUser?.exists() && dbUser.data().active) {
      setInvalidEmail(false);

      // Step 4: log user in firestore
      await fireStoreService.updateDocRef(dbUser.ref, {
        name: formData.get("name") as string,
        start_time: serverTimestamp(),
        // active: false,
      });

      setLoading(false);

      // Step Final: start the quiz
      startTest(true);
    } else {
      setInvalidEmail(true);
      setLoading(false);
    }
  };

  return (
    <div className="container-home">
      <img width="400" height="600" src={homeBgImg} alt="A pic with laptop kept on table with code opened code editor" />
      <div className="container-box">
        <form onSubmit={handleFormSubmit}>
          <div className="candidate-info">
            <h3>Candidate Assessment</h3>
            <div>
              <TextField required className="width-100 mt-1" color="primary" name="name" id="filled-basic" label="Full name" variant="filled" />
              <TextField
                required
                color="primary"
                className="width-100 mt-1"
                helperText={invalidEmail ? "Invalid email! enter valid details or check with HR." : ""}
                error={invalidEmail}
                name="email"
                id="filled-basic"
                label="Email"
                variant="filled"
                inputMode="email"
              />
            </div>
          </div>

          <LoadingButton className="width-100 mt-1" size="large" color="primary" variant="contained" type="submit" loading={loading} loadingPosition="center">
            Start Test
          </LoadingButton>
        </form>
        <div className="terms">
          <h3>Rules</h3>
          <hr />
          <ol>
            {terms.map((term, i) => (
              <li key={i}>{term}</li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Home;
