import { Button } from "@mui/material";
import { serverTimestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { fireStoreConfig, fireStoreService } from "../../firebase";
import { IQuestion, ITimestamp } from "../../models";
import CountDownTimer from "../countdowntimer/CountDownTimer";
import Question from "../question/Question";
import "./Questionair.scss";

type QuestionairProps = {
  questions: IQuestion[];
  submit: (submitted: boolean) => void;
  course?: string;
};

const Questionair = (props: QuestionairProps) => {
  const [percent, setPercent] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<IQuestion[]>(props.questions);
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion>(props.questions[0]);
  const [timerDuration, setTimerDuration] = useState<ITimestamp>({ hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    // get quiz question set
    (async function fetchQuestions() {
      let userData = await fireStoreService.fetchUserByRef();
      let timestamp = await fireStoreService.fetchDurationByCourse(userData?.course);
      setTimerDuration(timestamp);
    })();
  }, []);

  const handlePrevious = () => {
    const prevIndex = selectedQuestion.index - 1;
    if (prevIndex <= questions.length) {
      setPercent(getPercent(prevIndex));
      setSelectedQuestion(questions[prevIndex]);
    }
  };

  const handleNext = () => {
    const nextIndex = selectedQuestion.index + 1;
    if (nextIndex < questions.length) {
      setPercent(getPercent(nextIndex));
      setSelectedQuestion(questions[nextIndex]);
    }
  };

  const setPercentAndAnimate = () => {
    setPercent(getPercent(selectedQuestion.index + 1));
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("");
      }, 500);
    });
  };

  const handleSubmit = async () => {
    await setPercentAndAnimate();
    setLoading(true);

    // submit data to backend
    const questionPayload = {
      submit: true,
      end_time: serverTimestamp(),
      answers: questions.map((quest) => {
        return {
          question_id: quest.id,
          answer: quest.answer,
        };
      }),
    };
    await fireStoreService.updateDocById(fireStoreConfig.usersCollection, selectedQuestion.id, questionPayload);
    setLoading(false);
    props.submit(true);
  };

  const handleSelectedOption = (option: string) => {
    setQuestions((oldQuestions) =>
      oldQuestions.map((question) => {
        if (question.id === selectedQuestion.id) {
          question.answer = option;
        }
        return question;
      })
    );
  };

  const getPercent = (index: number) => {
    if (!selectedQuestion || !questions) {
      return 1;
    }
    const percent = (index * 100) / questions.length;
    return percent || 1;
  };

  const isLastQuestion = (): boolean => {
    return selectedQuestion.index === questions.length - 1;
  };

  return (
    <div className="container-box">
      <div className="question-header">
        <h3>
          Question
          <span className="question-steps">
            ({selectedQuestion.index + 1}/{questions.length})
          </span>
        </h3>
        {timerDuration ? (
          <div className="question-timer">
            Time Left: <CountDownTimer duration={timerDuration} />
          </div>
        ) : null}
      </div>
      <div className="progress-bar" style={{ "--percent": percent } as React.CSSProperties}></div>

      <Question question={selectedQuestion} selectOption={handleSelectedOption} />

      <div className="question-actions">
        <Button size="large" variant="outlined" color="primary" disabled={!selectedQuestion.index} onClick={handlePrevious}>
          Prev
        </Button>

        {!isLastQuestion() ? (
          <Button size="large" variant="contained" color="primary" disabled={!selectedQuestion.answer} onClick={handleNext}>
            Next
          </Button>
        ) : (
          <Button size="large" className="inline-flex" variant="contained" color="primary" disabled={!selectedQuestion.answer} onClick={handleSubmit}>
            {loading ? <div className="spinner"></div> : null} Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default Questionair;
