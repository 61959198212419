import React, { useEffect } from "react";
import { IQuestion } from "../../models";
import QuestionOption from "./QuestionOption";

type QuestionProps = {
  question: IQuestion;
  selectOption: (option: string) => void;
};

function Question(props: QuestionProps) {
  const { question, selectOption } = props;
  const [selectedOption, setSelectedOption] = React.useState<string>("");

  useEffect(() => {
    setSelectedOption(props.question.answer);
  }, [props.question]);

  const handleClick = (option: string) => {
    setSelectedOption(option);
    selectOption(option);
  };

  return (
    <div className="question-body">
      <p>{question?.question}</p>
      <ul className="question-options">
        {question?.options.map((option, index) => (
          <QuestionOption key={index} option={option} selected={selectedOption === option} selectOption={handleClick} />
        ))}
      </ul>
    </div>
  );
}

export default Question;
