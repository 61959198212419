import { initializeApp } from "firebase/app";
import { collection, doc, DocumentData, DocumentReference, Firestore, getDocs, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { appConfig } from "./Config";
import { IQuestion, ITimestamp } from "./models";

class FireStoreService {
  private _userRef: DocumentReference | undefined = undefined;
  private _fireStoreDb: Firestore;
  constructor(fireStoreDb: Firestore) {
    this._fireStoreDb = fireStoreDb;
  }

  async getCollections(collectionName: string) {
    const coll = collection(this._fireStoreDb, collectionName);
    const snapshot = await getDocs(coll);
    return snapshot.docs;
  }

  async getDocByEmail(collectionName: string, email: string) {
    const docs = await this.getCollections(collectionName);
    const doc = docs.find((doc) => doc.data().email === email);
    return doc;
  }

  async fetchQuestions(): Promise<IQuestion[]> {
    const questions = await this.getCollections(fireStoreConfig.questionsCollection);
    return questions.map((doc, index) => ({ id: doc.id, index, answer: "", ...doc.data() })) as IQuestion[];
  }

  async fetchDurationByCourse(course: string): Promise<ITimestamp> {
    const docs = await this.getCollections(fireStoreConfig.questionairDurations);
    const doc = docs.find((doc) => doc.data().course === course);
    return doc?.data().timestamp;
  }

  async updateDocById(collectionName: string, docId: string, docData: any) {
    const docRef = doc(this._fireStoreDb, collectionName, this.userRef?.id || "");
    return this.updateDocRef(docRef, docData);
  }

  async updateDocRef(docRef: DocumentReference, docData: any) {
    return await updateDoc(docRef, docData);
  }

  async fetchUserByRef() {
    if (!this.userRef) return;
    const snapshot = await getDoc(this.userRef);
    return snapshot.exists() ? snapshot.data() : undefined;
  }

  /* Setter & Getter */
  get userRef() {
    return this._userRef;
  }

  set userRef(ref: DocumentReference<DocumentData> | undefined) {
    this._userRef = ref;
  }
}

// Initialize Firebase
export const firebaseApp = initializeApp(appConfig.fireStore.config);
export const fireStoreDb = getFirestore(firebaseApp);
export const fireStoreService = new FireStoreService(fireStoreDb);
export const fireStoreConfig: any = appConfig.fireStore;
/* INFO: currently we are using non prod DB which will be deleted after 30 days starting from 3 nov 2022 */
