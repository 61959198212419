type AppConfig = {
  fireStore: {
    config: {
      apiKey: string;
      authDomain: string;
      projectId: string;
      storageBucket: string;
      messagingSenderId: string;
      appId: string;
    };
    usersCollection: string;
    questionsCollection: string;
    questionairDurations: string;
  };
  terms: string[];
};

export const appConfig: AppConfig = {
  fireStore: {
    config: {
      apiKey: "AIzaSyC3_CnM_Q9OPzLACTouyBecBEBhYH6ntCg",
      authDomain: "softareo-e17c4.firebaseapp.com",
      projectId: "softareo-e17c4",
      storageBucket: "softareo-e17c4.appspot.com",
      messagingSenderId: "493796546878",
      appId: "1:493796546878:web:95a61a9300f68945f7346f",
    },
    usersCollection: "users",
    questionsCollection: "questions",
    questionairDurations: "questionair_durations",
  },
  terms: [
    "You will be given 60 secs to answer each question.",
    // "Once you select your answer, it can not be change.",
    // "You won't be able to select any option when time is over.",
    "Do not refresh/reload tab or window while appearing for test",
    "Once you started test, you can't restart the test",
    "You will be disqualified if you leave in between.",
    "You will get points based on the correct answer only.",
    "There is no as such negative marking for incorrect answers.",
  ],
};
