import { useEffect, useState } from "react";
import "./App.scss";
import Home from "./features/home/Home";
import QuestionairComplete from "./features/questionair-complete/QuestionairComplete";
import Questionair from "./features/questionair/Questionair";
import { fireStoreService } from "./firebase";
import { IQuestion } from "./models";

import logo from "./assets/icons/internhire_logo.svg";

function App() {
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questions, setQuestions] = useState<IQuestion[]>([]);

  useEffect(() => {
    // get quiz question set
    (async function fetchQuestions() {
      let questionList = await fireStoreService.fetchQuestions();
      setQuestions(questionList);
    })();
  }, []);

  const handleSubmission = (submitted: boolean) => {
    setIsSubmitted(submitted);
  };

  return (
    <div className="container">
      <object type="image/svg+xml" data={logo} className="logo" aria-label="internhire website logo"></object>
      {questions?.length && isTestStarted ? (
        isSubmitted ? (
          <QuestionairComplete />
        ) : (
          <Questionair questions={questions} submit={handleSubmission} />
        )
      ) : (
        <Home startTest={setIsTestStarted} />
      )}
    </div>
  );
}

export default App;
